<template>
  <el-col>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t("message.procedural") }}</span>
      <el-button
        type="warning"
        icon="el-icon-close"
        size="small"
        @click="close"
        >{{ $t("message.close") }}</el-button
      >
    </header>
    <el-main>
      <div class="ml-0 mb-3">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>
              <b>{{ selected.remainder.name }}</b>
            </span>
          </div>
          <table class="table border-0 text-center">
            <thead>
              <tr>
                <th class="border-0">{{ $t("message.available") }}</th>
                <th class="border-0">{{ $t("message.measurement") }}</th>
                <th class="border-0">{{ $t("message.price") }}</th>
                <th class="border-0">{{ $t("message.total_price") }}</th>
                <th class="border-0">{{ $t("message.invalid") }}</th>
                <th class="border-0">{{ $t("message.valid") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-0">
                  {{ selected.remainder.used_medicine | formatNumber }}
                </td>
                <td class="border-0">
                  {{
                    selected.remainder.main_measurement
                      ? selected.remainder.main_measurement.name
                      : ""
                  }}
                </td>
                <td class="border-0">
                  {{ selected.remainder.price | formatMoney(2) }}
                </td>
                <td class="border-0">
                  {{
                    total_cost_price(
                      selected.remainder.price,
                      selected.remainder.used_medicine
                    ) | formatMoney(2)
                  }}
                </td>
                <td class="border-0">
                  {{ validItemCount() | formatNumber }}
                </td>
                <td class="border-0">
                  {{ invalidItemCount() | formatNumber }}
                </td>
              </tr>
            </tbody>
          </table>
        </el-card>
      </div>

      <el-tabs type="border-card">
        <el-tab-pane label="Медикаменты">
          <el-table
            v-loading="loading"
            :element-loading-text="$t('message.loading')"
            element-loading-spinner="el-icon-loading"
            ref="filterTable"
            :data="warehouse_products"
            size="mini"
            class="crm-mini-table"
            style="width: 100%"
          >
            <el-table-column :label="$t('message.warehouse')">
              <template slot-scope="props">{{
                props.row.warehouse ? props.row.warehouse.name : ""
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.patient')">
              <template slot-scope="props">{{
                props.row.patient_medicine
                  ? props.row.patient_medicine.patient.first_name +
                    " " +
                    props.row.patient_medicine.patient.surname
                  : ""
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.available')">
              <template slot-scope="props">{{
                props.row.quantity | formatNumber
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.price')">
              <template slot-scope="props"
                >{{ props.row.product.price | formatNumber(2) }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('message.date')">
              <template slot-scope="props">{{ props.row.created_at }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.user')">
              <template slot-scope="props">{{
                props.row.patient_medicine
                  ? props.row.patient_medicine.doctor.name
                  : ""
              }}</template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="Брак">
          <el-table
            v-loading="loading"
            :element-loading-text="$t('message.loading')"
            element-loading-spinner="el-icon-loading"
            ref="filterTable"
            :data="warehouse_invalid_products"
            size="mini"
            class="crm-mini-table"
            style="width: 100%"
          >
            {{}}
            <el-table-column :label="$t('message.warehouse')">
              <template slot-scope="props">{{
                props.row.warehouse ? props.row.warehouse.name : ""
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.patient')">
              <template slot-scope="props">{{
                props.row.patient_medicine
                  ? props.row.patient_medicine.patient.first_name
                  : ""
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.available')">
              <template slot-scope="props">{{
                props.row.quantity | formatNumber
              }}</template>
            </el-table-column>
            <el-table-column :label="$t('message.price')">
              <template slot-scope="props"
                >{{ props.row.product.price | formatNumber(2) }}
              </template>
            </el-table-column>

            <el-table-column :label="$t('message.date')">
              <template slot-scope="props">{{ props.row.created_at }}</template>
            </el-table-column>

            <el-table-column :label="$t('message.comment')">
              <template slot-scope="props">{{
                props.row.comment ? props.row.comment : ""
              }}</template>
            </el-table-column>

            <el-table-column :label="$t('message.user')">
              <template slot-scope="props">{{
                props.row.patient_medicine
                  ? props.row.patient_medicine.doctor.name
                  : ""
              }}</template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-col>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";

export default {
  mixins: [drawerChild, form],
  props: ["selected"],
  data() {
    return {
      loading: false,
      warehouse_products: [],
      warehouse_invalid_products: [],
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapActions({
      showWarehouseProducts: "remainders/show",
      editModel: "warehouseProducts/show",
      reportProduct: "warehouseProducts/reportProduct",
      empty: "remainders/empty",
    }),

    afterOpened() {
      if (this.selected.warehouseId && this.selected.warehouseId != "") {
        this.loading = true;
        const payload = {
          product_id: this.selected.remainder.id,
          warehouse_id: this.selected.warehouseId,
        };
        this.reportProduct(payload).then((response) => {
          this.warehouse_products = response.filter(
            (val) => val.is_invalid == false
          );
          this.warehouse_invalid_products = response.filter(
            (val) => val.is_invalid == true
          );
          this.loading = false;
        });
      } else {
        this.loading = true;

        const payload = { product_id: this.selected.remainder.id };
        this.reportProduct(payload).then((response) => {
          this.warehouse_products = response.filter(
            (val) => val.is_invalid == false
          );
          this.warehouse_invalid_products = response.filter(
            (val) => val.is_invalid == true
          );
          this.loading = false;
        });
      }
    },
    validItemCount() {
      // console.log(this.warehouse_invalid_products);
      return this.warehouse_products.reduce(function (total, quantity) {
        return total + quantity.quantity;
      }, 0);
    },
    invalidItemCount() {
      return this.warehouse_invalid_products.reduce(function (total, quantity) {
        return total + quantity.quantity;
      }, 0);
    },
    total_cost_price(val1, val2) {
      return val1 * val2;
    },
    afterClosed() {
      this.empty();
    },
  },
};
</script>